import { TextLabel } from "@sourceful/design-system-v3";
import { formatUnitPrice } from "@sourceful/shared-utils/engine-utils";

export type GetThumbLabelComponentProps = {
  value: number;
  filterName: string;
};

export const GetThumbLabelComponent = ({ value, filterName }: GetThumbLabelComponentProps) => (
  <TextLabel
    size="small"
    className="text-ui-darkest bg-ui-lightest py-2 px-8 border-2 border-ui-grey-100 rounded-pill"
  >
    {filterName === "price" ? `${formatUnitPrice(value, "GBP")}` : value}
  </TextLabel>
);
