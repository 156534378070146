import { SanityShopCard } from "@groq/shop/fragments/shopCard";
import { SanityShopSelectFilterOption } from "@groq/shop/fragments/shopFilter";
import { SliderState } from "../hooks/useCurrentShopFilters";

export type FilterOptionsByFilterId = Record<string, Set<string>>;

export const sortFilterOptionsByFilterId = (filterOptions: SanityShopSelectFilterOption[]) => {
  return filterOptions.reduce<FilterOptionsByFilterId>((acc, filterOption) => {
    if (!acc[filterOption.filterId]) {
      acc[filterOption.filterId] = new Set();
    }

    acc[filterOption.filterId].add(filterOption.id);
    return acc;
  }, {});
};

const isCardExcludedFromFilterPrice = (
  pricePerUnit: number | undefined | null,
  currentPrice?: SliderState
) => {
  if (currentPrice?.min === undefined || currentPrice?.max === undefined) {
    return false;
  }

  if (pricePerUnit === undefined || pricePerUnit === null) {
    return true;
  }

  if (pricePerUnit < currentPrice.min || pricePerUnit > currentPrice.max) {
    return true;
  }
};

const isCardExcludedFromFilterDelivery = (
  leadTimeMin: number | undefined | null,
  leadTimeMax: number | undefined | null,
  currentDelivery?: SliderState
) => {
  if (currentDelivery?.min === undefined || currentDelivery?.max === undefined) {
    return false;
  }

  if (
    leadTimeMin === undefined ||
    leadTimeMax === undefined ||
    leadTimeMin === null ||
    leadTimeMax === null
  ) {
    return true;
  }

  if (leadTimeMin < currentDelivery.min || leadTimeMax > currentDelivery.max) {
    return true;
  }
};

const hasValidFilterTags = (
  cardFiltersByFilterId: FilterOptionsByFilterId,
  activeFilters: FilterOptionsByFilterId
) => {
  for (let filterId in activeFilters) {
    const validOptions = activeFilters[filterId];

    if (validOptions.size === 0) continue; // if no filter tag is selected then it's valid

    let hasValidOption = false;
    for (let option of validOptions) {
      if (cardFiltersByFilterId[filterId]?.has(option)) {
        hasValidOption = true;
      }
    }

    if (!hasValidOption) return false;
  }

  return true;
};

export const applyFiltersToCards = (
  cards: SanityShopCard[] | null,
  activeFilters: FilterOptionsByFilterId,
  currentPrice?: SliderState,
  currentDelivery?: SliderState
) => {
  return (
    cards?.filter(card => {
      if (card._type === "shopPromoCard") return true;

      const cardFiltersByFilterId = sortFilterOptionsByFilterId(card.filterOptions || []);

      if (isCardExcludedFromFilterPrice(card.product?.pricePerUnit, currentPrice)) {
        return false;
      }

      if (
        isCardExcludedFromFilterDelivery(
          card.product?.leadTimeMin,
          card.product?.leadTimeMax,
          currentDelivery
        )
      ) {
        return false;
      }

      return hasValidFilterTags(cardFiltersByFilterId, activeFilters);
    }) ?? []
  );
};
