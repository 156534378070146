import { SanityProductCardSectionProduct } from "@groq/shop/productCardProduct";

export const getProductCardHref = (product: SanityProductCardSectionProduct | null) => {
  if (!product?.slug) {
    console.error("Product is missing slug", product);
    return "#";
  }

  if (product._type === "productPresetPage" && !product.productMain?.slug) {
    console.error("Product preset page is missing a productMain slug", product);
    return "#";
  }

  if (product._type === "productPresetPage") {
    return `/buy/${product.productMain?.slug}/${product.slug}`;
  }

  // covers productMain or directPurchase types
  return `/buy/${product?.slug}`;
};
