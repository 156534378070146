import { useQueryState } from "nuqs";
import { useCallback, useMemo } from "react";

export const getCurrentShopFilters = (filterString: string) => {
  if (filterString) {
    return new Set(filterString.split(","));
  }
  return new Set<string>();
};

export type SliderState = {
  min?: number;
  max?: number;
};

export const getCurrentShopSlider = (query: string) => {
  if (query) {
    const sliderState: SliderState = {};

    const [, min, max] = /^(\d*)_(\d*)$/.exec(query) || [];

    sliderState.min = min ? parseInt(min) : undefined;
    sliderState.max = max ? parseInt(max) : undefined;

    return sliderState;
  }

  return {};
};

export const useCurrentShopFilters = () => {
  const [filterString, setFilterString] = useQueryState("filters", {
    shallow: true,
    history: "push",
  });

  const [priceString, setPriceString] = useQueryState("price", {
    shallow: true,
    history: "push",
  });

  const [deliveryString, setDeliveryString] = useQueryState("delivery", {
    shallow: true,
    history: "push",
  });

  const currentFilters: Set<string> = useMemo(() => {
    return getCurrentShopFilters(filterString ?? "");
  }, [filterString]);

  const clearFilters = useCallback(() => {
    setFilterString(null);
    setPriceString(null);
    setDeliveryString(null);
  }, [setDeliveryString, setFilterString, setPriceString]);

  const addFilterOption = useCallback(
    (optionId: string) => {
      const newParams = [...Array.from(currentFilters), optionId];
      return setFilterString(newParams.join(","));
    },
    [currentFilters, setFilterString]
  );

  const removeFilterOption = useCallback(
    (optionId: string) => {
      const filteredParams = Array.from(currentFilters).filter(filter => filter !== optionId);

      if (filteredParams.length === 0) {
        return clearFilters();
      }

      setFilterString(filteredParams.join(","));
    },
    [currentFilters, setFilterString, clearFilters]
  );

  const currentPrice: SliderState = useMemo(() => {
    return getCurrentShopSlider(priceString ?? "");
  }, [priceString]);

  const addPrice = useCallback(
    (min?: number, max?: number) => {
      setPriceString(`${min ?? ""}_${max ?? ""}`);
    },
    [setPriceString]
  );

  const currentDelivery: SliderState = useMemo(() => {
    return getCurrentShopSlider(deliveryString ?? "");
  }, [deliveryString]);

  const addDelivery = useCallback(
    (min?: number, max?: number) => {
      setDeliveryString(`${min ?? ""}_${max ?? ""}`);
    },
    [setDeliveryString]
  );

  return {
    currentFilters,
    clearFilters,
    addFilterOption,
    removeFilterOption,
    currentPrice,
    addPrice,
    currentDelivery,
    addDelivery,
  };
};
