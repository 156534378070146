import { SanityShopPromoCard } from "@groq/shop/fragments/shopPromoCard";
import { getImageData } from "@lib/sanity";
import { getUrlFromTypeAndSlug } from "@lib/utils/urlHelper";
import Image from "next/image";
import Link from "next/link";
import { useMemo } from "react";

export interface PromotionalCardProps {
  card: SanityShopPromoCard;
  lazyLoadImage?: boolean;
}

export const PromotionalCard = ({
  card,
  lazyLoadImage = false,
}: Readonly<PromotionalCardProps>) => {
  const newWindow = card.link?.externalLink?.link
    ? card.link?.externalLink?.newWindow
    : card.link?.internalLink?.newWindow;

  const href = useMemo(() => {
    const externalLink = card.link?.externalLink;
    const internalLink = card.link?.internalLink;

    if (externalLink?.link) {
      return externalLink.link;
    }
    if (internalLink) {
      return getUrlFromTypeAndSlug(internalLink._type, internalLink.slug);
    }

    return "/";
  }, [card?.link]);

  // check if portrait or landscape image is available
  if (!card.portraitImages[0] || !card.landscapeImages[0]) {
    return null;
  }

  const portraitImage = getImageData(
    card.portraitImages[0],
    base => base.width(480).height(801),
    card.landscapeImages[0]?.asset.altText ?? card.title
  );

  const landscapeImage = getImageData(
    card.landscapeImages[0],
    base => base.width(801).height(480),
    card.landscapeImages[0]?.asset.altText ?? card.title
  );

  if (!portraitImage || !landscapeImage) {
    return null;
  }

  return (
    <Link
      href={href}
      aria-label={card.title}
      className="w-full h-full object-cover rounded-md overflow-hidden hover:shadow-mid transition-shadow duration-500 focus-visible:focus-ring"
      {...(newWindow && { target: "_blank", rel: "noopener noreferrer" })}
      prefetch={false}
    >
      <picture className="h-full w-full">
        <source
          media="(min-width: 640px)"
          srcSet={portraitImage?.url}
          width={portraitImage?.dimensions.width}
          height={portraitImage?.dimensions.height}
        />
        <Image
          src={landscapeImage.url}
          alt={landscapeImage.altText}
          width={landscapeImage.dimensions.width}
          height={landscapeImage.dimensions.height}
          loading={lazyLoadImage ? "lazy" : "eager"}
          className="object-cover h-full w-full"
          unoptimized
        />
      </picture>
    </Link>
  );
};
