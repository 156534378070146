import { RangeSliderInput } from "@sourceful/design-system-v3";
import { useMemo } from "react";
import { SanityShopFilter } from "../../../groq/shop/fragments/shopFilter";
import { SanityShopProductCard } from "../../../groq/shop/fragments/shopProductCard";
import { SanityShopPromoCard } from "../../../groq/shop/fragments/shopPromoCard";
import { GetThumbLabelComponent } from "./GetThumbLabelComponent";
import { SliderState, useCurrentShopFilters } from "./hooks/useCurrentShopFilters";

export type ShopFilterSliderProps = {
  filter: SanityShopFilter;
  cards: (SanityShopProductCard | SanityShopPromoCard)[];
  onValueCommit: (id: string, min?: number, max?: number) => void;
  value: SliderState;
};

const processCardToFindMinMax = (
  filterName: string,
  card: SanityShopProductCard | SanityShopPromoCard,
  currentMinMax: {
    min: number;
    max: number;
  }
) => {
  if (card._type !== "shopProductCard") return;

  if (filterName === "price") {
    const targetField = card.product?.pricePerUnit;

    if (targetField && targetField < currentMinMax.min) {
      currentMinMax.min = Math.floor(targetField);
    }

    if (targetField && targetField > currentMinMax.max) {
      currentMinMax.max = Math.ceil(targetField);
    }
  } else if (filterName === "delivery") {
    const minTargetField = card.product?.leadTimeMin;
    const maxTargetField = card.product?.leadTimeMax;

    if (minTargetField && minTargetField < currentMinMax.min) {
      currentMinMax.min = Math.floor(minTargetField);
    }

    if (maxTargetField && maxTargetField > currentMinMax.max) {
      currentMinMax.max = Math.ceil(maxTargetField);
    }
  }
};

export default function ShopFilterSlider({
  filter,
  cards,
  onValueCommit,
  value,
}: Readonly<ShopFilterSliderProps>) {
  const filterName = filter.filterType === "Price" ? "price" : "delivery";

  const { currentPrice, currentDelivery } = useCurrentShopFilters();

  const minMax = useMemo(() => {
    const currentMinMax = { min: Number.MAX_SAFE_INTEGER, max: -1 };

    cards.forEach(card => processCardToFindMinMax(filterName, card, currentMinMax));

    return currentMinMax;
  }, [cards, filterName]);

  const defaultValue = useMemo(() => {
    const slider = filterName === "price" ? currentPrice : currentDelivery;

    if (slider) {
      return [slider.min ?? minMax.min, slider.max ?? minMax.max];
    }

    return [minMax.min, minMax.max];
  }, [filterName, currentPrice, currentDelivery, minMax.min, minMax.max]);

  if (minMax.min === Number.MAX_SAFE_INTEGER || minMax.max === -1) {
    return null;
  }

  return (
    <RangeSliderInput
      min={minMax.min}
      max={minMax.max}
      defaultValue={defaultValue}
      value={value?.min && value?.max ? [value.min, value.max] : defaultValue}
      showLabels={true}
      onValueChange={([min, max]) => {
        onValueCommit(filterName, min, max);
      }}
      getThumbLabel={value => <GetThumbLabelComponent value={value} filterName={filterName} />}
    />
  );
}
