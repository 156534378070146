import { Accordion } from "@sourceful/design-system-v3";
import React from "react";

type FiltersProps = {
  children: React.ReactNode;
  openFilterValues: string[];
};

export default function Filters({ openFilterValues, children }: FiltersProps) {
  return (
    <div data-cy="shop-filters">
      <Accordion
        type="multiple"
        defaultValue={openFilterValues}
        className="border-0 divide-y divide-ui-grey-100"
      >
        {children}
      </Accordion>
    </div>
  );
}
