import { SanityShopFilter } from "@groq/shop/fragments/shopFilter";

export const sortActiveFilterOptionsByFilterId = (
  allFilters: SanityShopFilter[],
  activeFilters: Set<string>
) => {
  const result: Record<string, Set<string>> = {};
  allFilters.forEach(filter => {
    if (!result[filter._id]) {
      result[filter._id] = new Set();
    }

    filter.options.forEach(option => {
      if (activeFilters.has(option.id)) {
        result[filter._id].add(option.id);
      }
    });
  });

  return result;
};
