import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  IconArrowChevronDownLrg,
  TextBody,
  TextLabel,
} from "@sourceful/design-system-v3";

type FiltersProps = {
  title: string;
  info?: string;
  value: string;
  children: React.ReactNode;
};

export default function Filter({ title, info, value, children }: Readonly<FiltersProps>) {
  return (
    <AccordionItem value={value} className="border-0 text-ui-grey-950">
      <AccordionTrigger
        wrapperElement="h3"
        className="w-full flex justify-between items-center px-0 m-h-40 gap-16 mb-12 mt-12"
      >
        <TextBody size="small" weight="medium">
          {title}
          {info && (
            <TextLabel size="medium" className="ml-8 text-ui-grey-600">
              {info}
            </TextLabel>
          )}
        </TextBody>

        <IconArrowChevronDownLrg className="shrink-0 w-20 h-20 transition-transform duration-200" />
      </AccordionTrigger>
      <AccordionContent forceMount overflowWrapperClassName="-mx-4">
        <div className="px-4 pt-8 pb-20 flex flex-wrap gap-12">{children}</div>
      </AccordionContent>
    </AccordionItem>
  );
}
