import { Tooltip, TooltipContent, TooltipTitle, TooltipTrigger } from "@sourceful/design-system-v3";
import { SustainabilityIcon, SustainabilityIconName } from "@sourceful/shared-utils/sanity-utils";
import { FC } from "react";

export interface ProductSustainabilityIconProps {
  icon: SustainabilityIconName;
  title: string;
}

export function toPascalCase(text: string) {
  return text.replace(/(^\w|-\w)/g, text => text.replace(/-/, "").toUpperCase());
}
export const ProductSustainabilityIcon: FC<ProductSustainabilityIconProps> = ({ icon, title }) => {
  return (
    <Tooltip delayDuration={160}>
      <TooltipTrigger asChild>
        <div>
          <SustainabilityIcon name={icon} />
        </div>
      </TooltipTrigger>
      <TooltipContent variant="dark">
        <TooltipTitle text={title} />
      </TooltipContent>
    </Tooltip>
  );
};
