import { ProductSustainabilityIcon } from "@app/components/shop/ProductSustainabilityIcon";
import { SanityShopProductCard } from "@groq/shop/fragments/shopProductCard";
import { getImageData } from "@lib/sanity";
import { Badge, BadgeText, Figure, TextBody, TextLabel } from "@sourceful/design-system-v3";
import { doesProductUsePence, formatUnitPrice } from "@sourceful/shared-utils/engine-utils";
import Image from "next/image";
import Link from "next/link";
import { FC, useMemo } from "react";
import { getProductCardHref } from "./helpers/getProductCardHref";

export interface ProductCardProps {
  card: SanityShopProductCard;
  lazyLoadImage?: boolean;
}

export const ProductCard: FC<ProductCardProps> = ({ card, lazyLoadImage = false }) => {
  const leadTimeMin = card.product?.leadTimeMin;
  const leadTimeMax = card.product?.leadTimeMax;
  const moq = card?.product?.moq;

  const href = useMemo(() => {
    return getProductCardHref(card.product ?? null);
  }, [card.product]);

  let productImageInfo = null;

  if (card.images && card.images.length > 0) {
    productImageInfo = getImageData(
      card.images[0],
      base => base.width(400).height(400),
      card.images[0].asset.altText ?? card?.product?.name ?? ""
    );
  }

  return (
    <Link
      data-cy={`product-card-${href}`}
      href={href}
      // TODO: Remove this when PDP goes to app router
      prefetch={false}
      className="flex md:flex-col gap-x-12 md:gap-y-12 lg:gap-y-16 xl:gap-y-20 w-full rounded-md p-16 md:p-20 lg:p-24 xl:p-32 bg-ui-grey-50 overflow-hidden hover:shadow-mid transition-shadow duration-500 focus-visible:focus-ring relative z-0"
      data-product-base-id={card.product?.baseProductId}
      data-product-lead-time={`${leadTimeMin ?? ""}-${leadTimeMax ?? ""}`}
      data-product-price-per-unit={card.product?.pricePerUnit ?? ""}
    >
      <Figure
        state={"loaded"}
        aspectRatio={"oneOne"}
        contentLayout={"covered"}
        className="max-w-104 md:max-w-none w-full self-center bg-transparent"
      >
        {productImageInfo && (
          <Image
            src={productImageInfo.url}
            width={productImageInfo.dimensions.width}
            height={productImageInfo.dimensions.height}
            alt={productImageInfo.altText}
            loading={lazyLoadImage ? "lazy" : "eager"}
            unoptimized
          />
        )}
      </Figure>

      <div className="flex-1">
        {!!card.tags?.length && (
          <div className="flex gap-4 flex-wrap mb-12 md:mb-0 md:absolute md:top-16 xl:top-20 md:left-16 xl:left-20">
            {card.tags.map(tag => (
              <Badge size="md" key={tag._id} className="border-0" colour={tag.color}>
                <BadgeText>{tag.text}</BadgeText>
              </Badge>
            ))}
          </div>
        )}

        <div className="space-y-8">
          <div className="min-h-48 md:min-h-64">
            <TextBody
              asChild
              size="small"
              weight="medium"
              className="text-ui-grey-950 line-clamp-2"
            >
              <h2>{card.title}</h2>
            </TextBody>
          </div>

          <TextLabel asChild size="medium" className="text-ui-grey-800">
            <div className="space-y-4 h-48">
              {moq && <p className="whitespace-pre-wrap">Min units {moq}</p>}
              {leadTimeMin && leadTimeMax && (
                <p className={"whitespace-pre-wrap"}>
                  Delivered in {leadTimeMin} - {leadTimeMax} weeks
                </p>
              )}
            </div>
          </TextLabel>
        </div>

        <div className="mt-12 flex flex-col gap-y-20">
          <div className="flex gap-x-4 md:gap-x-8 xl:gap-x-12 text-ui-grey-950 h-16 md:h-20">
            {card.sustainabilityIcons?.map(({ title, icon }) => (
              <ProductSustainabilityIcon key={icon} title={title} icon={icon} />
            ))}
          </div>

          {card.product?.baseProductId && card.product?.pricePerUnit && (
            <div>
              <TextBody size="small" weight="medium" className="text-ui-grey-950">
                From{" "}
                {formatUnitPrice(
                  card.product?.pricePerUnit,
                  "GBP",
                  doesProductUsePence(card.product.baseProductId)
                )}
              </TextBody>
              <TextLabel size="medium" weight="regular" className="text-ui-grey-600">
                {` / unit`}
              </TextLabel>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};
