"use client";

import { SanityShopCard } from "@groq/shop/fragments/shopCard";
import { SanityShopFilter } from "@groq/shop/fragments/shopFilter";
import { useWindowSize } from "@react-hook/window-size";
import {
  Button,
  ButtonText,
  CheckboxTag,
  CloseButton,
  IconNavigationFilter,
  TextBody,
  TextLabel,
  Tooltip,
  TooltipContent,
  TooltipTitle,
  TooltipTrigger,
  cn,
  cva,
} from "@sourceful/design-system-v3";
import FocusTrap from "focus-trap-react";
import cloneDeep from "lodash/cloneDeep";
import Link from "next/link";
import { useParams } from "next/navigation";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntersection } from "react-use";
import { useUpdateIntercom } from "../../hooks/useUpdateIntercom";
import Filter from "./Filter";
import Filters from "./Filters";
import ShopFilterSlider from "./ShopFilterSlider";
import { applyFiltersToCards } from "./helpers/applyFiltersToCards";
import { sortActiveFilterOptionsByFilterId } from "./helpers/sortActiveFilterOptionsByFilterId";
import { useCurrentShopFilters } from "./hooks/useCurrentShopFilters";

const formCva = cva("hidden lg:flex", {
  variants: {
    open: {
      true: "flex flex-col fixed z-20 top-20 bottom-0 left-0 right-0 h-full pointer-events-auto bg-ui-lightest rounded-t-md",
    },
  },
});

export interface ShopFiltersProps {
  filters: SanityShopFilter[];
  cards: SanityShopCard[];
}

export default function ShopFilters({ filters, cards }: Readonly<ShopFiltersProps>) {
  const params = useParams<{ industry: string }>();

  const isIndustryPage = params?.industry !== undefined || false;

  const {
    currentFilters,
    removeFilterOption,
    clearFilters,
    addFilterOption,
    currentPrice,
    addPrice,
    currentDelivery,
    addDelivery,
  } = useCurrentShopFilters();

  const currentFiltersByFilterId = useMemo(() => {
    return sortActiveFilterOptionsByFilterId(filters, currentFilters);
  }, [filters, currentFilters]);

  const [filtersOpen, setFiltersOpen] = useState(false);

  const filteredCards = useMemo(() => {
    return applyFiltersToCards(cards, currentFiltersByFilterId, currentPrice, currentDelivery);
  }, [cards, currentDelivery, currentFiltersByFilterId, currentPrice]);

  const productCount = useMemo(() => {
    return filteredCards.filter(card => card._type === "shopProductCard").length;
  }, [filteredCards]);

  const onCheckedChange = useCallback(
    (checked: boolean | "indeterminate", value: string) => {
      return checked ? addFilterOption(value) : removeFilterOption(value);
    },
    [removeFilterOption, addFilterOption]
  );

  const onRangeSliderChange = useCallback(
    (id: string, min?: number, max?: number) => {
      if (id === "price") {
        addPrice(min, max);
      } else if (id === "delivery") {
        addDelivery(min, max);
      }
    },
    [addDelivery, addPrice]
  );

  const filterButtonRef = useRef<HTMLButtonElement>(null);

  // Track window size so we can close if it changes, defaults to 0 on SSR
  const [width] = useWindowSize();

  // Close filters if open when window size changes
  useEffect(() => {
    if (width > 0) {
      setFiltersOpen(false);
    }
  }, [width]);

  const getRootMargin = () => {
    if (isIndustryPage) {
      return "0px 0px -108px 0px"; // 40px (button) + 16px (gap) + 40px (button) + 16px (gradient)
    }
    return "0px 0px -56px 0px"; // 40px (button) + 16px (gradient)
  };

  const intersectionRef = useRef(null);

  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: getRootMargin(),
    threshold: 0,
  });

  const { showIntercom, hideIntercom } = useUpdateIntercom();

  return (
    <aside ref={intersectionRef} className={"w-full"}>
      {/* NOTE: Track this to reduce some overhead when not in mobile view, lg:hidden causes issues going from large on initial load to mobile/small */}
      {/* <div ref={intersectionRef} className="lg:hidden"></div> */}
      <div
        className={cn("flex flex-col gap-16 h-64 lg:hidden", {
          "fixed bottom-0 left-0 right-0 pt-8 pb-16 px-16 md:px-32 bg-ui-lightest":
            intersection &&
            !intersection.isIntersecting &&
            (intersection?.boundingClientRect.bottom ?? 0) > 0,
          "h-[120px]": isIndustryPage,
        })}
      >
        <div className="w-full h-16 bg-gradient-to-t from-ui-lightest absolute left-0 right-0 bottom-full"></div>
        <Button
          variant="outline"
          size="md"
          layout={"iconLeading"}
          className="w-full"
          onClick={() => {
            setFiltersOpen(!filtersOpen);
          }}
          ref={filterButtonRef}
        >
          <IconNavigationFilter className="size-24" />
          Filter
        </Button>
        {isIndustryPage && (
          <Button variant={"tertiary"} size="md" asChild>
            <Link href={"/"} className={"text-ui-blue-600"}>
              <ButtonText>Browse all products</ButtonText>
            </Link>
          </Button>
        )}
      </div>

      <FocusTrap
        active={filtersOpen}
        focusTrapOptions={{
          escapeDeactivates: (event: KeyboardEvent) => {
            if (event.key === "Escape") {
              setFiltersOpen(false);
              return true;
            }
            return false;
          },
          onActivate() {
            hideIntercom();
            document.body.classList.add("pointer-events-none", "overflow-hidden");
          },
          onDeactivate() {
            showIntercom();
            document.body.classList.remove("pointer-events-none", "overflow-hidden");
          },
        }}
      >
        <form className={cn(formCva({ open: filtersOpen }))}>
          <div className="grid overflow-y-scroll overflow-x-hidden p-20 ">
            <div className="flex flex-row-reverse justify-between items-center pb-12 border-b border-b-ui-grey-100 lg:flex-row">
              <CloseButton
                type="button"
                size={"md"}
                onClick={() => {
                  setFiltersOpen(false);
                  filterButtonRef.current?.focus();
                }}
                className="lg:hidden"
              />
              <h2 className="flex flex-row gap-8 items-center text-ui-grey-800">
                <IconNavigationFilter className="size-24" />
                <TextBody size="small" weight="medium">
                  Filter
                </TextBody>
              </h2>
              <Tooltip>
                <TooltipTrigger asChild>
                  <TextLabel
                    className="p-8 rounded-sm text-ui-grey-800 focus:focus-ring"
                    size="medium"
                    weight="medium"
                    asChild
                  >
                    <button type="button" onClick={clearFilters}>
                      Clear
                    </button>
                  </TextLabel>
                </TooltipTrigger>
                <TooltipContent>
                  <TooltipTitle text="Clear all filters" />
                </TooltipContent>
              </Tooltip>
            </div>

            <Filters openFilterValues={filters.map(f => f.name)}>
              {filters.map(filter => {
                return (
                  <Filter
                    key={filter._id}
                    title={filter.name}
                    info={filter.info}
                    value={filter.name}
                  >
                    {(filter.filterType === "Price" || filter.filterType === "Delivery") && (
                      <ShopFilterSlider
                        filter={filter}
                        cards={cards}
                        onValueCommit={onRangeSliderChange}
                        value={filter.filterType === "Price" ? currentPrice : currentDelivery}
                      />
                    )}

                    {(!filter.filterType || filter.filterType === "Select") &&
                      filter.options.map(option => {
                        let isDisabled = false;

                        if (!currentFilters.has(option.id)) {
                          // work out what cards would be available if this were the only option for the given filter
                          const oneStepChangeFilter = cloneDeep(currentFiltersByFilterId);
                          oneStepChangeFilter[filter._id] = new Set([option.id]); // overwrite the active filter options for this filter id

                          const filteredCards =
                            applyFiltersToCards(
                              cards,
                              oneStepChangeFilter,
                              currentPrice,
                              currentDelivery
                            )?.filter(card => card._type === "shopProductCard") ?? [];

                          isDisabled = filteredCards.length === 0;
                        }

                        return (
                          <CheckboxTag
                            key={option.id}
                            id={option.id}
                            value={option.id}
                            onCheckedChange={checked => {
                              onCheckedChange(checked, option.id);
                            }}
                            checked={currentFilters.has(option.id)}
                            className={
                              isDisabled ? "bg-ui-grey-50 text-ui-grey-300 cursor-not-allowed" : ""
                            }
                            disabled={isDisabled}
                          >
                            {option.name}
                          </CheckboxTag>
                        );
                      })}
                  </Filter>
                );
              })}
            </Filters>

            <Button
              type="button"
              variant={"primary"}
              size="md"
              className="my-16 lg:hidden flex-shrink-0"
              onClick={() => {
                setFiltersOpen(false);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              <ButtonText>
                See {productCount} {productCount === 1 ? "product" : "products"}
              </ButtonText>
            </Button>
          </div>
        </form>
      </FocusTrap>
    </aside>
  );
}
