"use client";
import { applyFiltersToCards } from "@app/components/shop/helpers/applyFiltersToCards";
import { SanityShopCard } from "@groq/shop/fragments/shopCard";
import { SanityShopFilter } from "@groq/shop/fragments/shopFilter";
import { Grid } from "@sourceful/design-system-v3";
import { useMemo } from "react";
import { ProductCard } from "./ProductCard";
import { PromotionalCard } from "./PromotionalCard";
import { sortActiveFilterOptionsByFilterId } from "./helpers/sortActiveFilterOptionsByFilterId";
import { useCurrentShopFilters } from "./hooks/useCurrentShopFilters";

export interface ShopCardsProps {
  cards: SanityShopCard[];
  filters: SanityShopFilter[];
}
export const ShopCards = ({ cards, filters }: ShopCardsProps) => {
  const { currentFilters, currentPrice, currentDelivery } = useCurrentShopFilters();

  const currentFiltersByFilterId = useMemo(() => {
    return sortActiveFilterOptionsByFilterId(filters, currentFilters);
  }, [filters, currentFilters]);

  const filteredCards = useMemo(() => {
    return applyFiltersToCards(cards, currentFiltersByFilterId, currentPrice, currentDelivery);
  }, [cards, currentDelivery, currentFiltersByFilterId, currentPrice]);

  return (
    <Grid className="grid-cols-1 md:grid-cols-3">
      {filteredCards.map((card, index) => {
        const lazyLoadImage = index > 4;
        if (card._type === "shopPromoCard") {
          return <PromotionalCard key={card._id} card={card} lazyLoadImage={lazyLoadImage} />;
        }

        return <ProductCard key={card._id} card={card} lazyLoadImage={lazyLoadImage} />;
      })}
    </Grid>
  );
};
